@import '../../assets/styles/modules';

header {
    position: relative;
    width:    100%;
    z-index:  300;
}

.wrapper {
    display:                grid;
    grid-template-columns:  80px 1fr auto;
    grid-template-rows:     72.53px;
    align-items:            center;
    justify-content:        center;
    padding:                40px 0 0;

    &Mobile {
        width:           100%;
        display:         flex;
        flex-wrap:       nowrap;
        justify-content: space-between;
        align-items:     center;
        padding:         15px 20px;

        svg {
            width: 39px;
            height: 35px;
        }
    }
}

.buttons {
    display:         grid;
    grid-auto-flow:  column;
    grid-column-gap: 30px;
    align-items:     center;
    position:        relative;

    @include media-breakpoint-up(md) {

        > div {
            position: relative;
        }
    }
}

.navList {
    display:         flex;
    justify-content: center;

    a {
        display:        block;
        font-family:    $paragraph-title-font;
        font-style:     normal;
        font-weight:    600;
        font-size:      15px;
        line-height:    17.89px;
        letter-spacing: 0.01em;
        color:          color(grey);

        &:not(:last-child) {
            padding-right: 30px;
        }


        &:hover {
            color:      color(danger_dark);
            transition: $transition;
        }
    }
}
