@import '../../assets/styles/modules';

.button {
    cursor:     pointer;
    transition: $transition;
}

.disabled {
    filter: grayscale(1);
}
