@import '../../assets/styles/modules';

.help {
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
        margin-bottom: 282px;

        > div {
            > div {
                grid-column-gap: 58px;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        > div {
            > div {
                grid-template-columns: 40% 60%;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: 434px;
    }

    h2 {

        @include media-breakpoint-up(xxl) {
            max-width: 522px;

            & ~ div {
                max-width: 525px;
            }
        }
    }

    > div > div > div {

        @include media-breakpoint-up(md) {
            padding-left: 4rem;
        }

        @include media-breakpoint-up(xxl) {
            padding-left: 3rem;
        }
    }
}
