@import '../../assets/styles/modules';

.sectionContainer {
    display:     flex;
    align-items: center;
}

.reverse {

    @include media-breakpoint-up(md) {
        div {
            &:first-child {
                grid-column-start: 2;
                padding-left:      initial;
            }
        }

        figure {
            grid-row-start:    1;
            grid-column-start: 1;
        }
    }
}

