//#a1 {
//
//    scroll-behavior: smooth;
//    max-height:       100vh;
//    overflow-y:       scroll;
//    scroll-snap-type: y mandatory;
//
//    section {
//        scroll-snap-align: center;
//
//        @include media-breakpoint-down(md){
//            scroll-snap-align: start;
//            scroll-margin-top: 20px;
//        }
//    }
//}


footer {

    &,
    a {
        font-family: $paragraph-title-font;
    }
}
