@import '../../assets/styles/modules';

.socialsList {
    display:               grid;
    grid-template-columns: repeat(3, 30px);
    grid-auto-rows:        28px;
    grid-auto-flow:        row;
    grid-gap:              spacer-y(.5) spacer-x(1);
    height:                initial;
    padding-top:           spacer-y(1);

    svg {
        transition: $transition;

        &:hover {
            cursor: pointer;

            path:first-child,
            g > path:first-child {
                transition: $transition;
                fill:       color(danger_dark);
            }
        }
    }
}
