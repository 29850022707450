a {
  color: color(secondary);
  transition: all 0.4s ease-in-out;
  font-size: 1rem;

  &,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
    outline: 0;
  }

  &:hover {
    text-decoration: none;
    transition: all 0.4s ease-in-out;
  }

  &.selected {
    color: lighten(color(light), 20%) !important;
    border-bottom: 3px solid lighten(color(light), 20%);
  }
}
