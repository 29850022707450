@import '../../assets/styles/modules';

.lang {
    background: transparent;
    color:      color(danger);
    border:     none;
    padding:    initial;

    &:hover {
        color: color(danger_dark);
    }

    div {
        display:        flex;
        align-items:    center;
        font-style:     normal;
        font-weight:    600;
        font-size:      15px;
        line-height:    25px;

        letter-spacing: 0.01em;
    }

    @include media-breakpoint-up(md) {

        & ~ div {
            left:     -30px;
            position: absolute;
        }
    }

    svg {
        width:       28px;
        height:      28px;
        fill:        color(danger);
        margin-left: spacer-x(.25);
    }
}

.open {

    img {
        transform: rotate(180deg);
    }
}

.langsList {
    @include set-background(lighten(color(light), 10%));
    position:      absolute;
    top:           spacer-y(1.5);
    left:          0;
    min-width:     119px;
    box-shadow:    $box_shadow;
    border-radius: 8px;

    ul {
        padding: 15px 15px 8px 15px;
    }

    li {
        font-family:    $paragraph-title-font;
        border-radius:  0;

        transition:     $transition;

        font-weight:    600;
        font-size:      15px;
        line-height:    25px;
        letter-spacing: 0.01em;

        &:not(:last-child) {
            padding-bottom: 10px;
        }

        &:hover {
            color:      color(danger_dark);
            transition: $transition;
            cursor:     pointer;
        }
    }
}

li.active {
    color: color(danger_dark);
}

button.langsMobile {
    margin:      initial;
    padding-top: 7px;

    > div {
        width: 100%;
    }

    span {
        font-size: $font12;
    }

    & ~ .langsList {
        margin:     initial;
        position:   relative;
        box-shadow: none;
        top:        0;
        min-width:  initial;

        ul {
            padding: 5px 0 0 0;
        }

        li {
            text-align:     initial;
            font-style:     normal;
            font-weight:    600;
            font-size:      $font12;
            line-height:    $height14;
            letter-spacing: 0.01em;

            &:not(:last-child) {
                padding-bottom: 15px;
            }
        }

        li.active {
            color: color(danger_dark);
        }
    }
}
