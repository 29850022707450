@import '../../assets/styles/modules';

.signIn {
    @include set-background(color(danger));
    border-radius:   8px;
    border:          none;
    font-style:      normal;
    font-weight:     600;
    font-size:       15px;
    line-height:     25px;
    letter-spacing:  0.01em;

    display:         flex;
    flex-direction:  row;
    justify-content: center;
    align-items:     center;

    position:        static;
    right:           0;
    top:             calc(50% - 49px / 2);
    background:      color(danger);

    @include media-breakpoint-up(md){
        width:           148px;
        height:          49px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width:           148px;
            height:          49px;
        }
    }

    &:hover {
        @include set-background(color(danger_dark));
        transition: $transition;
    }
}
