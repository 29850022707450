@import '../../assets/styles/modules';

.item {
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        display:               grid;
        grid-column-gap:       11px;
        grid-template-columns: 50% 50%;
    }

    figure {
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 612px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 712px;
        }
    }
}

.simple {
    font-family:    $base-font-family;
    font-size:      $font20;
    line-height:    $height14*2;
    font-weight:    400;
    letter-spacing: 0.02em;
    text-align:     left;

    @include media-breakpoint-up(md) {
        height: 140px;
        width: 100%;
        max-width:  400px;
    }
}

.itemContainer {
    height:   100%;
    position: relative;
    z-index:  20;

    @include media-breakpoint-up(md) {
        display:      flex;
        flex-wrap:    wrap;
        align-items:  center;
        padding-left: 130px;
    }

    h2 {
        font-style:     normal;
        color:          color(dark);
        font-weight:    700;
        letter-spacing: 0.02em;
        text-align:     left;
        padding:        initial;
        margin-bottom:  10px;
        left:           20px;
        top:            99px;
        font-size:      33px;
        line-height:    35px;

        @include media-breakpoint-up(sm) {
            font-size:     40px;
            line-height:   45px;
            margin-bottom: 25px;
        }

        @include media-breakpoint-up(lg) {
            font-size:     55px;
            line-height:   60px;
            margin-bottom: 30px;
        }

        @include media-breakpoint-up(xxl) {
            font-size:   72px;
            line-height: 70px;
        }
    }

    p {
        font-family:    $base-font-family;
        font-size:      $font12;
        font-weight:    400;
        text-align:     left;
        line-height:    16.8px;
        letter-spacing: 0.02em;

        @include media-breakpoint-up(md) {
            font-size:   $font20;
            line-height: $height14*2;
        }

        @include media-breakpoint-up(sm) {
            font-size:  16px;
            line-height: 24px;
        }
    }
}

.textContainer {
    padding: initial;

    &,
    div {
        color:      color(dark);
        background: transparent;
    }
}

.download {
    display:           grid;
    grid-auto-flow:    column;
    grid-auto-columns: max-content;
    grid-column-gap:   30px;

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, 1fr);
        padding-top:           spacer-y(.75);
        grid-column-gap:       spacer-x(.5);
        max-width: 350px;
    }

    svg {

        @include media-breakpoint-down(md) {
            width: 157px;
        }

        &:hover {
            cursor: pointer;

            rect {
                stroke: color(danger_dark);
            }
        }
    }
}

.phonesList {
    display: flex;

    @include media-breakpoint-down(md) {
        flex-wrap:  nowrap;
        overflow-x: scroll;
    }

    @include media-breakpoint-up(md) {
        grid-column-start: 1;
        grid-row-start:    1;
    }

    figure {
        width:     100%;
        transform: initial;
    }

    img {
        width:  239.77528381347656px;
        height: initial;

        @include media-breakpoint-up(md) {
            width: 100%;
            max-width:  200px;
        }

        @include media-breakpoint-up(xxl) {
            max-width:  250px;
        }
    }
}
