@import '../../assets/styles/modules';

.your_security {
    grid-template-rows: 30rem;
    align-items:        start;
    padding-top:        1rem;
    z-index:            0;

    @include media-breakpoint-down(md) {

        > div {
            height:      30rem;
            align-items: flex-start;
        }
    }

    @include media-breakpoint-up(md) {
        grid-template-rows: initial;
        align-items:        start;
        padding-top:        initial;
        margin-bottom:      167.8px;
        height:             43rem;
        z-index:            0;

        > div > div {
            width: 100%;
        }
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: 257.8px;
        height:        50rem;
    }

    h2 {

        @include media-breakpoint-up(md) {
            max-width: 520px;

            ~ div {
                margin-bottom: 50px;
            }
        }
    }

    p {

        @include media-breakpoint-up(md) {
            line-height: 19.14px !important;
        }
    }

    &:after {

        @include media-breakpoint-up(md) {
            content:             '';
            display:             block;
            position:            absolute;
            width:               100%;
            height:              100%;
            right:               0;
            background-image:    url(../../assets/images/bg/s1.png);
            background-repeat:   no-repeat;
            background-position: 100% 0;
            background-size:     47%;
            z-index:             0;
            top:                 4rem;
            pointer-events: none;
        }

        @include media-breakpoint-down(xl) {
            background-size: 35%;
        }

        @include media-breakpoint-down(lg) {
            background-size: 30%;
        }

        @include media-breakpoint-up(xl) {
            top: 1rem;
        }

        @include media-breakpoint-up(xxl) {
            background-size: initial;
            top:             0;
        }
    }

    &:before {
        background-image: url(../../assets/images/bg/bg__1_1.png);
        background-size:  100%;
        grid-row-start:   2;
        height:           90rem;
        top:              -4rem;

        @include media-breakpoint-up(sm) {
            top: -18rem;
        }

        
        @include media-breakpoint-up(md) {
            background-image: url(../../assets/images/bg/bg1.png);
            background-size:  113% 108%;
            width:            1629px;
            height:           1489.24px;
            top:              -13.94rem;
            z-index:          0;
        }

        @include media-breakpoint-up(xl) {
            background-size: 111% 123%;
        }
    }
}
