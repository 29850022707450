h1,
%h1,
h2,
%h2,
h3,
%h3,
h4,
%h4,
h5,
%h5,
h6,
%h6 {
    font-family:    $base-font-family;
    font-weight:    600;
    padding-bottom: 1rem;

    small {
        font-weight: 400;
        font-size:   0.7rem;
    }

    &.section-title {
        font-family: $paragraph-title-font;
        font-weight: 100;
        font-style:  italic;
        line-height: 0.9;
        color:       darken(color(dark), 34%);

        @include media-breakpoint-up(md) {
            font-size: 3.3em;
        }
    }

    &.intotext-title {
        font-family: $paragraph-title-font;
        font-weight: 200;
        font-style:  italic;
        font-size:   1.85em;
        line-height: 0.9;
        color:       transparentize(darken(color(danger), 14%), 0.2);

        @include media-breakpoint-down(md) {
            font-size: 1.45em;
        }
    }
}

h1,
%h1 {
    font-size:   3rem;
    line-height: 1.8rem;
}

h2,
%h2 {
    font-size:   2.5rem;
    line-height: 2rem;
}

h3,
%h3 {
    font-size:   2rem;
    line-height: 2rem;
}

h4,
%h4 {
    font-size:   1.3rem;
    line-height: 1.5rem;

    @include media-breakpoint-down(md) {
        font-size: 0.9rem;
    }
}

h5,
%h5 {
    font-size: 1rem;
}

h6,
%h6 {
    font-size:   0.7rem;
    line-height: 0.7rem;
}
