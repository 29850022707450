@import '../../assets/styles/modules';

.how_work {
    position: relative;
    z-index: 100;

    @include media-breakpoint-up(md) {
        margin-bottom: 280px;
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: 434px;
    }

    @include media-breakpoint-down(xl) {
        > div {
            > div {
                grid-template-columns: 45% 55%;
            }
        }
    }

    h2 {

        @include media-breakpoint-up(md) {
            & ~ div {
                max-width: 505px;
            }
        }
    }

    > div {
        z-index: 80;

        @include media-breakpoint-up(md) {
            > div > div {
                padding-left: initial;

                &:first-child {
                    @include media-breakpoint-up(md) {
                        padding-left: 120px;
                    }

                    @include media-breakpoint-down(xl) {
                        padding-left: 60px;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            > div > div:first-child {
                padding: 0 20px;
            }
        }
    }

    &:before {

        @include media-breakpoint-up(md) {
            background-image: url(../../assets/images/bg/bg__5.png);
            background-size:  40%;
            width:            3864px;
            height:           2020px;
            top:              -37rem;
            left:             -2rem;
        }

        @include media-breakpoint-up(xl) {
            background-size: 40%;
            width:           3864px;
            height:          2020px;
            top:             -50rem;
            left:            initial;
        }
    }

    @include media-breakpoint-down(md) {

        h2 {
            ~ div {
                padding-bottom: 15px;
            }
        }
    }
}
