button {
    cursor:          pointer;
    margin:          0 auto;
    //padding:         .67rem 1.34rem;
    font-family:     $paragraph-title-font;
    font-weight:     600;
    font-size:       12px;
    line-height:     25px;
    letter-spacing:  0.01em;
    text-align:      left;
    display:         flex;
    align-items:     center;
    justify-content: center;
    white-space:     nowrap;
    @include outline;
}
