@import '../../assets/styles/modules';

.wrapper {
    display:               grid;
    grid-template-columns: auto 1fr auto;
    position:              relative;
    z-index:               100;
    padding-bottom:        65px;

    * {
        font-style:  normal;
        font-size:   $font20;
        line-height: $height14*2;
        font-weight: $weight500;
        color:       color(dark);
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-auto-flow:        row;
        text-align:            center;
        grid-row-gap:          spacer-y(1);

        div {

            &:nth-child(2) {
                grid-row-start: 3;
            }
        }
    }

    > div {
        display:        grid;
        grid-auto-flow: row;
        align-items:    end;
        padding:        spacer-y(.5) 0;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
}

.dateSocial {
    font-size:      $font20;
    line-height:    $height14*2;
    font-weight:    $weight500;
    letter-spacing: 0;
}

.terms {
    text-align: center;
    transition: $transition;

    a {
        color: color(secondary_light);

        &:hover {
            transition: $transition;
            color:      color(danger_dark);
        }
    }
}

.contacts {
    padding-left: spacer-x(4);

    @include media-breakpoint-down(md) {
        grid-row-gap: spacer-y(.5);
    }

    a,
    p {
        &:hover {
            color: color(danger_dark);
        }
    }
}
