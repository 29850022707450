@import '../../assets/styles/modules';

.will_provide {
    z-index:       190;
    margin-bottom: 60px;

    @include media-breakpoint-down(md) {
        margin-top: 59.76px;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 247px;
        z-index:       200;

        > div {
            > div {
                grid-column-gap: 47px;
            }
        }

        h2 {
            max-width: 574px;

            & ~ div {
                max-width: 520px;
            }
        }
    }

    &:before {
        background-image: url(../../assets/images/bg/bg__2.png);

        @include media-breakpoint-up(md) {
            top:    -42rem;
            height: 86rem;
            left:   -4rem;
        }
        @include media-breakpoint-up(xxl) {
            left: 0;
        }
    }
}
