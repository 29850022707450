@import '../../assets/styles/modules';

.together {
    margin-top: 60px;

    @include media-breakpoint-up(md) {
        margin-bottom: 196.8px;
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: 296.8px;
    }

    @include media-breakpoint-down(xl) {
        > div {
            > div {
                grid-template-columns: 65% 35%;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        > div {
            > div {
                grid-template-columns: 75% 25%;
            }
        }
    }

    h2 {
        ~ div {
            margin-bottom: initial;

            @include media-breakpoint-up(md) {
                margin-bottom: 50px;
            }
        }
    }

    p {

        @include media-breakpoint-up(md) {
            line-height: 19.14px !important;
        }
    }

    > div > div > div {

        @include media-breakpoint-up(md) {
            padding-left: 4rem;
        }

        @include media-breakpoint-up(xxl) {
            padding-left: 4rem;
        }
    }

    &:before {
        background-image: url(../../assets/images/bg/bg__6_1.png);
        z-index:          20;
        height:           80rem;
        top:              -17rem;

        @include media-breakpoint-down(md) {
            background-size: 100% 100%;
        }

        @include media-breakpoint-up(md) {
            background-image:    url(../../assets/images/bg/bg6.png);
            background-position: 100% 0;
            position:            absolute;
            height:              83rem;
            top:                 -23rem;
        }
        @include media-breakpoint-up(xxl) {
            height: 89rem;
            top:    -30rem;
        }
    }

    figure {
        z-index:  20;
        position: relative;
        top:      -3rem;

        @include media-breakpoint-down(md) {
            display: block;
            top:     initial;

        }
    }


    img {
        width: 352.2px;

        @include media-breakpoint-up(md) {
            width: 100%;
            max-width: 420.36px;
        }

        @include media-breakpoint-up(xxl) {
            margin-left: spacer-x(2);
        }
    }
}
