//box-shadow
$box_shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);

//font size
$font12:     12px;
$font20:     20px;

//line height
$height14:   14px;

//font weight
$weight500:    500;
