html {
  font-size: $base-font-size;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  font-size: $font-size;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: $base-line-height;
  margin: 0;
  @include set-background(lighten(color(light), 2%));
}

html,
body {
  height: 100%;
  width: 100%;
}
