// base width
$max-width-lend : 1440px;
$max-width: 1920px;
$max-width-small: 1440px;

// typography
$base-font-size: 18px;
$base-line-height: 1.45;

$font-size: 1rem !default;

// font and weight for heading

$base-font-family: 'Gotham Pro', sans-serif;
$base-font-weight: 300;
$base-font-family-lending: 'Gotham Pro', sans-serif;

$paragraph-title-font: Gilroy, sans-serif;
$paragraph-font-size: 1rem;

// breakpoints
$media-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px,
  // super extra
  xxl: 1920px
) !default;

// transition
$transition: all 0.4s ease-in-out;
