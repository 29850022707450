@import '../../assets/styles/modules';

.span {
    display:          block;
    width:            18px;
    height:           2px;
    margin-bottom:    3px;
    background-color: color(danger_dark);
}

.navButton {
    display:          block;
    transform-origin: 16px 11px;
    float:            left;
    outline:          0;
    border:           0;
    padding:          12px;

    span {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:focus {
        border:     medium none rgb(111, 255, 176);
        box-shadow: rgb(111, 255, 176) 0 0 2px 2px;
        outline:    0;
    }

    &:hover {
        span {
            width: 18px;
        }
    }

    &.active {
        span:nth-of-type(1) {
            transform: rotate(45deg) translate(9px, 1px);
            width:     22px;
        }

        span:nth-of-type(2) {
            opacity:        0;
            pointer-events: none;
        }

        span:nth-of-type(3) {
            transform: rotate(-45deg) translate(6px, 2px);
            width:     22px;
        }
    }
}
