section {
  width: 100%;

  div {
    @include outline;
  }
}

#a1 {
  & > section {
    z-index: 3;
  }
}