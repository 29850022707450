@import '../../assets/styles/modules';
@import "your_security";
@import "will_provide";
@import "reasons";
@import "on_guard_of_order";
@import "help";
@import "how_work";
@import "together";

.section {
    position: relative;

    figure {
        width: 712px;
    }

    @include media-breakpoint-down(md){
        > div {
            padding: 0 20px;
        }

        &.how_work {
            & > div {
                padding: initial !important;
            }
        }
    }
}

.your_security,
.will_provide,
.reasons,
.on_guard_of_order,
.help,
.how_work,
.together {

    display: grid;

    @include media-breakpoint-down(md) {
        display: block;
    }

    @include media-breakpoint-up(md) {
        display:     flex;
        align-items: center;
    }

    h2 {
        font-size:      72px;
        font-weight:    700;
        line-height:    70px;
        letter-spacing: 0.02em;
        text-align:     left;

    }

    &:before {
        content:           '';
        display:           block;
        background-repeat: no-repeat;
        position:          absolute;
        width:             100%;
        pointer-events: none;
    }
}
