@import '../../assets/styles/modules';

.items {
    position:         absolute;
    top:              3rem;
    right:            1rem;
    z-index:          293;
    display:          block;
    max-width:        100%;
    margin-top:       0;
    background-color: lighten(color(light), 10%);
    box-shadow:       $box_shadow;
    padding:          15px;
    border-radius:    8px;
    opacity:          0;
    visibility:       hidden;
    transition:       opacity 0.3s, visibility 0s linear 0.3s;

    &.open {
        opacity:          1;
        visibility:       visible;
        transition-delay: 0s;
    }

    a {
        display:           block;
        text-align:        left;
        max-width:         100%;
        background-repeat: no-repeat;
        transition:        background-position 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
        text-decoration:   none;
        color: rgba(148, 149, 154, 1);
        font-family:       $paragraph-title-font;
        font-weight:       600;
        font-size:         $font12;
        line-height:       $height14;
        letter-spacing:    0.01em;

        &:not(:last-of-type) {
            padding-bottom: 15px;
        }

        &:hover {
            background-position: 90% 50%;
        }
    }
}

.auth {
    //padding:    10px;
    margin-top: 10px;
    font-size:  12px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width:           83px;
        height:          34px;
        color: white;
    }
}