@import '../../assets/styles/modules';

.eulaText {
  padding: spacer-y(2) 0;

  @include media-breakpoint-down(md) {
    > div {
      padding: 0 20px;
    }
  }

  div {
    background:     transparent;
    font-size:      20px;
    font-weight:    400;
    line-height:    28px;
    letter-spacing: 0.02em;
    text-align:     left;
    color:          #120D26;
  }

  h3 {
    font-family:    $base-font-family;
    font-weight:    700;
    letter-spacing: 0.02em;
    text-align:     left;

    @include media-breakpoint-down(md) {
      font-size:   20px !important;
      line-height: 30px !important;
    }

    @include media-breakpoint-up(md) {
      font-size:   32px !important;
      line-height: 32px !important;
    }

    @include media-breakpoint-up(xxl) {
      font-size:   72px !important;
      line-height: 70px !important;
    }
  }


  @include media-breakpoint-down(md) {

    ul {
      padding-left: initial !important;
      display:      block;
      text-align:   justify;
      font-size:    .8rem;
    }

    li {
      display: block;
    }
  }
}
