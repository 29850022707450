@import '../../assets/styles/modules';

.reasons {
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
        margin-bottom: 227px;
    }

    @include media-breakpoint-down(xl) {

        > div {
            > div {
                grid-template-columns: 65% 35%;
            }
        }
    }

    h2 {

        @include media-breakpoint-down(sm){
            font-size: 33px !important;
        }

        @include media-breakpoint-up(sm) {
            font-size: 36px;

            & ~ div {
                max-width: 525px;
            }
        }

        @include media-breakpoint-up(xxl) {
            max-width: 687px;
        }



    }

    > div > div > div {

        @include media-breakpoint-up(md) {
            padding-left: 4rem;
        }

        @include media-breakpoint-up(xxl) {
            padding-left: 2rem;
        }
    }

    &:before {

        @include media-breakpoint-up(md) {
            background-image:    url(../../assets/images/bg/bg__3.png);
            background-position: 100% 0;
            position:            absolute;
            height:              110rem;
            top:                 -73rem;
            right:               0;
        }
        @include media-breakpoint-up(xxl) {
            top:   -60rem;
            right: 0;
        }
    }
}
