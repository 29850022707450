@import '../../assets/styles/modules';

.on_guard_of_order {
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
        margin-bottom: 154px;
    }

    h2 {

        @include media-breakpoint-up(md) {
            //white-space: nowrap;
            max-width: 553px;

            & ~ div {
                max-width: 525px;
            }
        }

        @include media-breakpoint-up(xxl) {
            max-width: 759px;
        }
    }

    &:before {
        background-image: url(../../assets/images/bg/bg__4_1.png);
        z-index:          20;
        height:           80rem;
        top:              -35rem;

        @include media-breakpoint-up(sm) {
            top: -26rem;
        }

        @include media-breakpoint-up(md) {
            background-image: url(../../assets/images/bg/bg__4.png);
            position:         absolute;
            height:           120rem;
            top:              -72rem;
            left:             -6rem;
        }
        @include media-breakpoint-up(xl) {
            left: 0;
        }
    }
}
