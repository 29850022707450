@import '../../assets/styles/modules';

.figure {
    width:           100%;
    display:         flex;
    align-items:     center;
    justify-content: center;
}

.image {
    width: 100%;
}
