@import '../../assets/styles/modules';

.navBar {
    display:     flex;
    outline:     currentcolor none medium;
    max-width:   100%;
    margin:      0;
    align-items: center;
    z-index:     500;
}
