input {
  font-family: $base-font-family;
  width: 100%;
  position: relative;

  @include outline;
  @include autofill;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='checkbox'] {
    cursor: pointer;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[placeholder],
  &::-moz-placeholder,
  &:-moz-placeholder,
  &:-ms-input-placeholder {
    text-overflow: ellipsis;
    color: color(secondary);
  }
}
